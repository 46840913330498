import { ZoomMtg } from "@zoomus/websdk";
import { useEffect } from "react";
import React from 'react'
import crypto from "crypto"; // crypto comes with Node.js

function generateSignature(apiKey, apiSecret, meetingNumber, role) {
  return new Promise((res, rej) => {
    // Prevent time sync issue between client signature generation and zoom
    const timestamp = new Date().getTime() - 30000;
    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
      "base64"
    );
    const hash = crypto
      .createHmac("sha256", apiSecret)
      .update(msg)
      .digest("base64");
    const signature = Buffer.from(
      `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
    ).toString("base64");

    res(signature);
  });
}

var apiKey = "ub8YTffaTn-xDATVx52mqw";
var apiSecret = "JfAjBKibkLyJSWZIXU2DVdEBHOaQrKlMNw7V";
var leaveUrl = "https://app.thetncollege.com/"; // our redirect url


var signature = "";

const App = () => {
  // loading zoom libraries before joining on component did mount
  useEffect(() => {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.6/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    (
      async () => {

        let paramData = await JSON.parse(atob(window.location.pathname.split('/')[window.location.pathname.split('/').length-1]))
        console.log('params',paramData)
        await generateSignature(apiKey, apiSecret, paramData.meetingNumber, 0).then((res) => {
          signature = res;
        }); // need to generate based on meeting id - using - role by default 0 = javascript    
        console.log('params signature', signature)
        initiateMeeting(paramData);
      }
    )();
  }, []);

  const initiateMeeting = (paramData) => {
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log('success init',success);
         console.log('signature',signature) 
        ZoomMtg.join({
          signature: signature,
          meetingNumber: paramData.meetingNumber,
          userName: paramData.userName,
          apiKey: apiKey,
          userEmail: paramData.userEmail,
          passWord: paramData.passWord,
        
          success: (success) => {
            console.log('success join',success);
          },
          error: (error) => {
            console.log('error join',error);
          },
        });
      },
      error: (error) => {
        console.log('error init',error);
      },
    });
  };

  return <div className="App">Zoom</div>;
};

export default App;